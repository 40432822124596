import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header align-items-center border-0 mt-4" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body pt-3" }
const _hoisted_6 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_7 = { class: "d-flex flex-row-fluid flex-wrap align-items-center" }
const _hoisted_8 = { class: "flex-grow-1 me-2" }
const _hoisted_9 = {
  href: "#",
  class: "text-gray-800 fw-bolder text-hover-primary fs-6"
}
const _hoisted_10 = { class: "text-muted fw-bold d-block pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown1 = _resolveComponent("Dropdown1")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "fw-bolder text-dark" }, "Latest Media"),
        _createElementVNode("span", { class: "text-muted mt-1 fw-bold fs-7" }, "Articles and publications")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([{ 'mb-7': _ctx.list.length - 1 !== index }, "d-flex align-items-sm-center"])
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: "symbol-label",
              style: _normalizeStyle(`background-image: url('${item.image}')`)
            }, null, 4)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("a", _hoisted_9, _toDisplayString(item.title), 1),
              _createElementVNode("span", _hoisted_10, "Size: " + _toDisplayString(item.size), 1)
            ]),
            _createElementVNode("span", {
              class: _normalizeClass([`badge-light-${item.color}`, "badge fs-8 fw-bolder my-2"])
            }, _toDisplayString(item.status), 3)
          ])
        ], 2))
      }), 128))
    ])
  ], 2))
}