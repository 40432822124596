import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body pt-5" }
const _hoisted_6 = { class: "symbol symbol-50px me-5" }
const _hoisted_7 = { class: "symbol-label" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "d-flex align-items-center flex-row-fluid flex-wrap" }
const _hoisted_10 = { class: "flex-grow-1 me-2" }
const _hoisted_11 = {
  href: "#",
  class: "text-gray-800 text-hover-primary fs-6 fw-bolder"
}
const _hoisted_12 = { class: "text-muted fw-bold d-block fs-7" }
const _hoisted_13 = { class: "badge badge-light fw-bolder my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder text-dark" }, "Trends"),
        _createElementVNode("span", { class: "text-muted mt-1 fw-bold fs-7" }, "Latest tech trends")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown3)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([{ 'mb-7': _ctx.list.length - 1 !== index }, "d-flex align-items-sm-center"])
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createElementVNode("img", {
                src: item.image,
                class: "h-50 align-self-center",
                alt: ""
              }, null, 8, _hoisted_8)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("a", _hoisted_11, _toDisplayString(item.title), 1),
              _createElementVNode("span", _hoisted_12, _toDisplayString(item.text), 1)
            ]),
            _createElementVNode("span", _hoisted_13, _toDisplayString(item.badge), 1)
          ])
        ], 2))
      }), 128))
    ])
  ], 2))
}